import { Box, Grid, Typography } from "@mui/material";
import PageTitle from "./PageTitle";

const AboutPage = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PageTitle title={"Story"} />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "justify" }}>
        <p>
          Harsh winter brought yet another metal project into this pitiful existence. The world offers no requirement for it, yet it was always the
          selfish need of restless artists to bestow their senseless, even redundant creations upon the said world.
        </p>

        <p>
          So from the icy plains of despair rose Nagavret, whose name has no real meaning other than it sounds metal, with an intention to offer a new
          vision of darkness and frost, a vision not necessarily of aggressive tendencies, but of peace and tranquillity, offered by coldness of some
          hypothetical utopian perfection, with falling snow that prophesies blissful, pleasant nothing above the struggles of everyday.
        </p>

        <p>
          Fuelled by the horrors of anxiety, this band marches through blizzard of its own troublesome thoughts, unbounded by common merits of metal
          musical scene, yet embracing the very best of it, with high packed, down-tuned-to-hell distortion and virtual string ensemble, riding on the
          winds of blast beats.
        </p>

        <p>
          The uninvited yet persistent nameless dread serves as inspiration to produce raw material that surpasses genres, yet undoubtedly lurks in
          domain of extreme metal, with a clear potential to resonate successfully with those that are no strangers to this same dread, evoked by
          life’s discomforts and cruel truths of being.
        </p>

        <p>
          Sadly, to produce more numerous series of wintry gospels, the certain conditions must be met (with emphasis on temperature reaching absolute
          zero) so there might be noticeable absence of new releases and ever present impression that the idea is dead. But rest assured, it only
          hibernates (more often than not), waiting for the moment to rise again in a fashion most appropriate.
        </p>

        <p>
          So as the times for preaching the imminent ice age remain nigh, this seemingly lifeless revenant’s non-periodic return is ensured as climate
          change, as it is always seeking new ways of expression and consequently, better production opportunities.
        </p>

        <p>There you have it. A properly convoluted introduction to a distressed snow monster named Nagavret.</p>
      </Grid>
    </Grid>
  );
};

export default AboutPage;

import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ice1 from "./static/ice1.jpg";
import { Fade, Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { SiteConsts } from "./consts/SiteConsts";
import PageContainer from "./PageContainer";
import Snowfall from "react-snowfall";
import logo from "./static/nagavret.png";
import logo2 from "./static/logo-mobile.png";

const drawerWidth = 240;

const LinkItem1 = styled(ListItemText)(({ theme }) => ({
  color: "white",
  textDecoration: "none",
  fontSize: "45px",
  fontFamily: "system-ui",
  fontWeight: 100,
}));

const ListItemButton1 = styled(ListItemButton)(({ theme }) => ({
  ":hover": {
    backgroundColor: "#212020",
  },
}));

export default function Layout(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [pageID, setPageID] = React.useState(null);
  const [isFrist, setIsFirst] = React.useState(true);

  const fadeTimeout = 1000;

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleMenuClick = (id) => {
    setMobileOpen(false);

    if (id != null) {
      if (isFrist) {
        setIsOpen(true);
        setPageID(id);
        setIsFirst(false);
      } else {
        setIsOpen(false);

        setTimeout(() => {
          setIsOpen(true);
          setPageID(id);
        }, fadeTimeout);
      }
    }
  };

  const drawer = (
    <Box>
      <List sx={{ fontWeight: 350 }}>
        <ListItemButton1 onClick={() => handleMenuClick("about")}>
          <LinkItem1 disableTypography primary={"STORY"} />
        </ListItemButton1>
        <ListItemButton1 onClick={() => handleMenuClick("disco")}>
          <LinkItem1 disableTypography primary={"RECORDS"} />
        </ListItemButton1>
        <ListItemButton1 onClick={() => handleMenuClick("contact")}>
          <LinkItem1 disableTypography primary={"CONTACT"} />
        </ListItemButton1>
      </List>
    </Box>
  );

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    // <Fade in={true} timeout={5000}>
    <Box>
      <Box
        component="div"
        sx={{
          // position: "absolute",
          // width: "100%",
          // height: "100%",
          minHeight: "100vh",
          backgroundImage: `url(${ice1})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          display: "flex",
        }}
      >
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            display: { xs: "block", sm: "none" },
            backgroundColor: "black",
          }}
        >
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: "none" } }}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}></Typography>
            <Box sx={{ marginRight: "-15px" }}>
              <img
                style={{
                  //flexGrow: 1,
                  maxHeight: "50px",
                  verticalAlign: "middle",
                }}
                src={logo2}
                alt=""
              />
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            PaperProps={{
              sx: {
                backgroundColor: "black",
                backgroundImage: "none",
              },
            }}
            container={container}
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            PaperProps={{
              sx: {
                backgroundColor: "black",
              },
            }}
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Grid container sx={{ width: { sm: `calc(100% - ${drawerWidth}px)` }, alignSelf: "start" }}>
          <Grid item xs={12} sx={{ display: { xs: "none", sm: "block" } }}>
            <Box justifyContent="flex-end" sx={{ display: { xs: "none", sm: "flex" } }}>
              {/* <img
                  style={{
                    //flexGrow: 1,
                    maxWidth: "100%",
                    maxHeight: "100px",
                    verticalAlign: "middle",
                    position: "relative",
                  }}
                  src={logo}
                  alt=""
                /> */}
              <Typography
                sx={{
                  color: "black",
                  fontSize: "5vw",
                  fontFamily: SiteConsts.FONT_KEY_GLOBAL_2,
                  textShadow: "3px 4px 10px #080808",
                  marginRight: "10px",
                }}
              >
                NAGAVRET
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Toolbar sx={{ display: { xs: "block", sm: "none" } }} />
            <Box sx={{ p: 3 }} mx={{ md: 10, lg: 30 }}>
              <PageContainer isOpen={isOpen} pageID={pageID} fadeTimeout={fadeTimeout} />
              {/* <Box component="img" src={ice1} sx={{ maxWidth: "100%", height: "auto", padding: 0, margin: 0 }}></Box> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Snowfall />
    </Box>
    // </Fade>
  );
}

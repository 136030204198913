import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import DiscoPage from "./pages/DiscoPage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import { Fade, Grid } from "@mui/material";

const Page = (props) => {
  switch (props.pageID) {
    case "disco":
      return <DiscoPage />;
    case "about":
      return <AboutPage />;
    case "contact":
      return <ContactPage />;
    default:
      return null;
  }
};

export default function PageContainer(props) {
  //const diapl = props.isOpen ? "flex" : "none";

  return (
    <Fade in={props.isOpen} timeout={props.fadeTimeout}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
          },
          position: "relative",
          zIndex: 1,
        }}
      >
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Paper sx={{ p: 3, backgroundColor: "rgb(0 0 0 / 64%)", color: "white" }}>
              <Page pageID={props.pageID} />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  );
}

import { Box, Grid, Paper, Skeleton, Stack, Typography } from "@mui/material";
import PageTitle from "./PageTitle";
import { useEffect, useRef, useState } from "react";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

import demo1 from "../static/demo12.jpg";

//import "./audio.scss";
//import "react-h5-audio-player/src/styles.scss";
import AudioPlayer from "react-h5-audio-player";
//import "react-h5-audio-player/lib/styles.css";
import "./audio.css";

const getSongRootPath = () => {
  const { REACT_APP_ENV } = process.env;

  if (REACT_APP_ENV == "Development") {
    return "https://static.nagavret.alvrineom.com";
  } else if (REACT_APP_ENV == "Test") {
    return "https://static.nagavret.alvrineom.com";
  } else if (REACT_APP_ENV == "PreProduction") {
    return "https://static.nagavret.alvrineom.com";
  } else if (REACT_APP_ENV == "Production") {
    return "https://static.nagavret.alvrineom.com";
  }
};

const Albums = [
  {
    title: "Nagavret",
    albumType: "Demo",
    path: "",
    index: 1,
    image: demo1,
    songs: [
      {
        name: "1. North",
        duration: "",
        path: `${getSongRootPath()}/demo1/368fabf6-0249-44a5-bce2-2c66e77d8374.mp3`,
        index: 1,
      },
      {
        name: "2. Huronian Glaciation",
        duration: "",
        path: `${getSongRootPath()}/demo1/27425f28-b777-4553-9dba-697918d35b25.mp3`,
        index: 2,
      },
    ],
  },
];

const Album = (props) => {
  const { selectedAlbum, setSelectedAlbum, selectedSong } = props;
  const { title, index: key, songs, image, albumType } = props.album;
  const [coverLoaded, setCoverLoaded] = useState(false);
  const player = useRef();
  const firstSong = songs[0];

  useEffect(() => {
    if (selectedAlbum != key) {
      stop();
    } else {
      play();
    }
  }, [selectedAlbum, selectedSong]);

  const play = () => {
    let playPromise = player.current.audio.current.play(); // async peace of shit

    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          // Automatic playback started!
          // Show playing UI.
        })
        .catch((error) => {
          // Auto-play was prevented
          // Show paused UI.
        });
    }
  };

  const stop = () => {
    player.current.audio.current.pause();
    player.current.audio.current.currentTime = 0;
    //player.current.audio.current.src = ""; //error for some reason
  };

  const goNext = (songIndex) => {
    if (songIndex != null) {
      if (selectedAlbum === key) {
        const lastSongIndex = songs.length;

        if (songIndex != lastSongIndex) {
          const newSongIndex = songIndex + 1;
          const song = songs.find((x) => x.index == newSongIndex);
          props.handleListItemClick(song, key);
        }
      }
    }
  };

  const goPrev = (songIndex) => {
    if (songIndex != null) {
      if (selectedAlbum === key) {
        const firstSongIndex = 1;

        if (songIndex != firstSongIndex) {
          const newSongIndex = songIndex - 1;
          const song = songs.find((x) => x.index == newSongIndex);
          props.handleListItemClick(song, key);
        }
      }
    }
  };

  const onPlay = () => {
    if (selectedAlbum != key) {
      // This is triggered when one album is playing
      // and user clicks 'Play' button on another (inactive) album
      // (first song from list is played defaultly)
      props.handleListItemClick(firstSong, key);
    }
  };

  return (
    <Box key={key}>
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} md={12}>
          <Typography variant="h4">{title}</Typography>
          <Typography variant="h6">{albumType}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ p: 2, backgroundColor: "black" }}>
                {coverLoaded ? null : (
                  <Box sx={{ height: 0, overflow: "hidden", paddingTop: "100%", position: "relative" }}>
                    <Skeleton variant="rectangular" sx={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} />
                  </Box>
                )}
                <img
                  style={{
                    //flexGrow: 1,
                    maxWidth: "100%",
                    verticalAlign: "middle",
                    opacity: coverLoaded ? 1 : 0,
                    transition: "opacity 2000ms",
                  }}
                  src={image}
                  alt=""
                  onLoad={() => setCoverLoaded(true)}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <AudioPlayer
                src={selectedAlbum === key ? selectedSong?.path : firstSong.path}
                //src={selectedAlbum === key ? selectedSong?.path : null}
                // src={getSongPath() + selectedSong?.path}
                //onPlay={(e) => setSelectedAlbum(key)}
                ref={player}
                defaultDuration="00:00"
                defaultCurrentTime="00:00"
                showSkipControls={true}
                showJumpControls={false}
                onClickNext={(e) => goNext(selectedSong?.index)}
                onClickPrevious={(e) => goPrev(selectedSong?.index)}
                onPlaying={() => onPlay()}
                //onPlay={() => onPlay()} sometimes triggers on 'pause' - bug?
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <List component="nav" sx={{ backgroundColor: "black" }}>
            {songs.map((song, index) => {
              return (
                <ListItemButton
                  key={index}
                  selected={selectedSong?.index === index + 1 && selectedAlbum === key}
                  onClick={(event) => props.handleListItemClick(song, key)}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "#1b375f",
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "#1b375f",
                    },
                    "&.Mui-focusVisible": {
                      backgroundColor: "#333333",
                    },
                    ":hover": {
                      backgroundColor: "#333333",
                    },
                  }}
                >
                  <ListItemText primary={song.name} />
                </ListItemButton>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

const DiscoPage = (props) => {
  const [selectedSong, setSelectedSong] = useState(null);
  const [selectedAlbum, setSelectedAlbum] = useState(null);

  const handleListItemClick = (song, albumID) => {
    setSelectedSong(song);
    setSelectedAlbum(albumID);
  };

  return (
    <Grid container spacing={2} rowSpacing={5}>
      <Grid item xs={12}>
        <PageTitle title={"Records"} />
      </Grid>

      <Grid container item xs={12} rowSpacing={4}>
        {Albums.toReversed().map((album, index) => {
          return (
            <Grid key={index} item xs={12}>
              {/* <Paper sx={{ backgroundColor: "rgb(0 0 0 / 64%)", color: "white" }}> */}
              <Album
                album={album}
                selectedSong={selectedSong}
                selectedAlbum={selectedAlbum}
                setSelectedAlbum={setSelectedAlbum}
                handleListItemClick={handleListItemClick}
              />
              {/* </Paper> */}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default DiscoPage;

import { Box, Grid, Typography } from "@mui/material";

const PageTitle = (props) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Typography variant="h3" sx={{ fontWeight: 150, fontFamily: "system-ui" }}>
        {props.title}
      </Typography>
    </Box>
  );
};

export default PageTitle;

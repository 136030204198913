import { Box, Grid, IconButton, Typography } from "@mui/material";
import PageTitle from "./PageTitle";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import YouTubeIcon from "@mui/icons-material/YouTube";

const ContactPage = (props) => {
  return (
    <Grid container spacing={2} rowSpacing={5}>
      <Grid item xs={12}>
        <PageTitle title={"Contact"} />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ p: 2, textAlign: "center" }}>
          <IconButton variant="outlined" color="primary" size="large" href="https://www.youtube.com/@Nagavret" target="_blank">
            <YouTubeIcon sx={{ fontSize: { xs: "11vw", sm: "4vw" } }} />
          </IconButton>

          <IconButton variant="outlined" color="primary" size="large" href="mailto:alvrineom@gmail.com" target="_blank">
            <EmailIcon sx={{ fontSize: { xs: "11vw", sm: "4vw" } }} />
          </IconButton>
          <IconButton variant="outlined" color="primary" size="large" href="https://alvrineom.com" target="_blank">
            <HomeIcon sx={{ fontSize: { xs: "11vw", sm: "4vw" } }} />
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          Copyright @ {new Date().getFullYear()} Alvrineom
        </Box>
      </Grid>
    </Grid>
  );
};

export default ContactPage;

import * as React from "react";
import "./App.css";

import { BrowserRouter } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material/styles";
// import ErrorBoundary from "./components/site/error/ErrorBoundary";

import Layout from "./Layout";
import ErrorBoundary from "./error/ErrorBoundary";
import { Backdrop, Box, Fade } from "@mui/material";
import logo2 from "./static/logo-mobile.png";
import { Snowfall } from "react-snowfall";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#000000",
    },
    typography: {
      allVariants: {
        color: "white",
      },
    },
  },
});

function App() {
  const [open, setOpen] = React.useState(true);

  setTimeout(() => setOpen(false), 5000);

  return (
    <>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <Backdrop sx={{ backgroundColor: "#000000" }} open={open} transitionDuration={{ exit: 2000 }}>
            {/* <img class="blink" src={logo2} alt=""/> */}
            <Fade in={true} timeout={5000}>
              <img style={{ maxWidth: "100%", height: "auto" }} src={logo2} alt="" />
            </Fade>
            <Snowfall />
          </Backdrop>

          <Fade in={!open} timeout={5000}>
            <Box>
              <Layout />
            </Box>
          </Fade>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
}

export default App;
